<template>
  <div
    v-if="candidates.fetching === 'resolved'"
    id="wrapper-mobile-size"
  >
    <div
      class="mobile-size"
    >
      <header>
        <div class="title">
          Pilih Kandidat
        </div>
      </header>
      <section>
        <div class="wrapper-alert">
          <b-alert
            class="alert"
            show
            variant="primary"
          >
            <div class="alert-body">
              <feather-icon
                class="mr-25"
                icon="AlertCircleIcon"
              />
              <span class="ml-25">Anda hanya memiliki 1 kali kesempatan dalam E-vote</span>
            </div>
          </b-alert>
        </div>

        <div class="info-calon-uni">
          Calon Ketua Umum ILUNI UI 2022-2025
        </div>
        <div
          class="wrapper-list-candidate"
        >
          <div
            v-for="candidate in candidates.data"
            :key="candidate.id"
            class="candidate"
          >
            <div class="no-urut">
              {{ candidate.no_urut }}
            </div>
            <img :src="`${candidate.photo}pilih-kandidat.png`">
            <div class="candidate-name">
              {{ candidate.nama }}
            </div>
            <button @click="onSelectCandidate(candidate)">
              Pilih
            </button>
          </div>
        </div>
      </section>

      <div
        class="logout"
        @click="logout"
      >
        <feather-icon
          class="mr-25"
          icon="LogOutIcon"
        />
        <span>Logout</span>
      </div>
    </div>
    <b-modal
      id="modal-popup-candidate"
      v-model="isPopupCandidate"
      centered
      hide-footer
      hide-header
      hide-header-close
      content-class="modal-popup-candidate"
      size="xs"
    >
      <div
        v-if="selectedCandidate"
        class="wrapper-content-modal"
      >
        <div class="wrapper-button-cancel">
          <div @click="isPopupCandidate = false, selectedCandidate = null">
            <feather-icon
              icon="XIcon"
              size="20"
            />
          </div>
        </div>
        <div class="wrapper-content-propup-candidate">
          <div class="wrapper-propup-no-urut">
            <div class="propup-no-urut">
              {{ selectedCandidate.no_urut }}
            </div>
            <img :src="`${selectedCandidate.photo}pilih-kandidat.png`">
          </div>
          <div class="propup-candidate-name">
            {{ selectedCandidate.nama }}
          </div>
          <button
            class="propup-candidate-button"
            @click="isPopupCandidate = false, isModalOpen = true"
          >
            Pilih
          </button>
        </div>
      </div>
    </b-modal>
    <b-modal
      id="modal-default"
      v-model="isModalOpen"
      centered
      title="Default Modal"
      hide-footer
      hide-header
      hide-header-close
      content-class="modal-vote"
      size="xs"
      @hidden="onCloseHide"
    >
      <div class="wrapper-content-modal">
        <div class="title">
          Apakah Anda yakin ingin memilih <span class="name">{{ selectedCandidate ? selectedCandidate.nama : '' }}</span>?
        </div>
        <div class="info">
          Kesempatan memilih hanya 1 kali dan tidak dapat diulang
        </div>
        <div class="footer">
          <div @click="isModalOpen = false">
            Batalkan
          </div>
          <div
            class="ok"
            @click="voteCandidate"
          >
            Ya, Saya yakin
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<style>
[dir] .modal .modal-content {
  border-radius: 20px !important;
}
</style>

<style scoped>
  @media (max-width: 640px) {
    .mobile-size {
      width: 100vw !important;
    }
    .mobile-size header {
      display: none !important;
    }
    .mobile-size section {
      margin-top: 0 !important;
    }

    .logout {
      display: none !important;
    }
  }
  #wrapper-mobile-size {
    display: flex;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    background-color: #E5E5E5;
  }

  .mobile-size {
    width: 360px;
    height: 100vh;
    background-color: #F9F9F9;
    overflow-y: auto;
  }

  .mobile-size header {
    height: 44px;
    display: flex;
    padding: 10px 10px 10px 24px;
    background-color: #FFFFFF;
    z-index: 999;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 1px;
    position: fixed;
    width: 360px;
  }

  .mobile-size header .title {
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #1B1B1B;
  }

  .mobile-size section {
    margin-top: 45px;
  }

  .mobile-size section .alert .alert-body span {
    /* margin: 10px; */
    color:  #1B1B1B;
  }

  .mobile-size section .wrapper-alert {
    padding: 10px 24px;
    background-color: #FFFFFF;
  }

  .mobile-size section .wrapper-list-candidate {
    padding: 16px 8px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 4.75rem;
    width: 100%;
    height: calc(100vh - 160px);
    align-content: center;
  }

  .candidate {
    width: 24%;
    min-height: 260px;
    background-color: #FFFFFF;
    border-radius: 20px;
    position: relative;
    border: 1px solid #E0E0E0;
    margin-bottom: 16px;
  }

  .candidate-name {
    margin-top: 10px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 120%;
    /* identical to box height, or 17px */
    text-align: center;
    color: #1B1B1B;
  }

  .candidate button {
    position: absolute;
    bottom: 0;
    margin: 0 auto;
    transform: translate(-50%, -50%);
    left: 50%;
    background: #FEDC00;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
    border-radius: 35px;
    padding: 8px 16px;
    border: none;
    color: #000000;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
  }

  .candidate .no-urut {
    position: absolute;
    top: 0;
    left: -1px;
    width: 41px;
    height: 22px;
    border-top-left-radius: 20px;
    border-bottom-right-radius: 20px;
    background: #FEDC00;
    opacity: 0.8;
    text-align: center;
    font-weight: 600;
    font-size: 14px;
    color: #1B1B1B;
  }

  .candidate img {
    width: 99.9%;
    height: 135px;
  }

  .wrapper-content-modal {
    position: relative;
    padding: 2px;
  }
  .wrapper-content-modal .title {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #1B1B1B;
  }

  .wrapper-content-modal .title .name {
    color: #4FBBEA
  }

  .wrapper-content-modal .info {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #1B1B1B;
    margin-top: 12px;
  }
  .wrapper-content-modal .footer {
    margin-top: 36px;
    display: flex;
    justify-content: space-between;
  }

  .wrapper-content-modal .footer div {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    /* identical to box height */
    text-align: center;
    cursor: pointer;
  }

    .wrapper-content-modal .footer .ok {
      color: #4FBBEA
    }

   .logout {
    position: absolute;
    bottom: 24px;
    background: #FFFFFF;
    right: 24px;
    cursor: pointer;
    padding: 8px 16px;
    border-radius: 20px;
    color: #1B1B1B;
  }

  .wrapper-button-cancel {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 12px;
  }
  .wrapper-button-cancel div {
    cursor: pointer;
    color: #1B1B1B;
  }

  .wrapper-content-propup-candidate {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .wrapper-propup-no-urut {
    width: 200px;
    height: 300px;
    border-radius: 20px;
    position: relative;
  }

  .propup-no-urut {
    position: absolute;
    top: 0;
    left: -1px;
    background: #FEDC00;
    opacity: 0.8;
    border-top-left-radius: 20px;
    border-bottom-right-radius: 20px;
    padding: 5px 22px;
    color: #1B1B1B;
    font-weight: 600;
    font-size: 16px;
  }

  .wrapper-content-propup-candidate img {
    display: flex;
    width: 200px;
    height: 300px;
    object-fit: cover;
    border-radius: 20px;
  }

  .wrapper-content-propup-candidate  .propup-candidate-name {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 120%;
    text-align: center;
    margin-top: 16px;
  }

  .wrapper-content-propup-candidate  .propup-candidate-button {
    background: #FEDC00;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
    border-radius: 35px;
    padding: 12px 16px;
    border: none;
    color: #000000;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    width: 100%;
    margin-top: 24px;
    margin-bottom: 16px;
  }
  .info-calon-uni {
    text-align: center;
    font-size: 16px;
    line-height: 20px;
    color: #000000;
    margin-top: 39px;
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: 100vw
  }
</style>
<script>
import Ripple from 'vue-ripple-directive'
import { BAlert } from 'bootstrap-vue'
import API from '@/api'

export default {
  components: {
    BAlert,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      candidates: {
        data: [],
        fetching: 'idle',
      },
      selectedCandidate: null,
      isModalOpen: false,
      isPopupCandidate: false,
    }
  },
  created() {
    this.fetchCandidate()
  },
  mounted() {

  },
  methods: {
    async fetchCandidate() {
      try {
        this.candidates.fetching = 'pending'
        const { data } = await API.evote.candidates()
        this.candidates.data = data
        this.candidates.fetching = 'resolved'
      } catch (error) {
        console.log(error)
        this.candidates.fetching = 'rejected'
      }
    },
    onSelectCandidate(candidate, isGolput) {
      this.selectedCandidate = candidate
      if (isGolput) {
        this.selectedCandidate.nama = 'Tidak Memilih Kedua Kandidat'
      }
      // this.isModalOpen = true
      this.isPopupCandidate = true
    },
    onCloseHide() {
      this.selectedCandidate = null
    },
    async voteCandidate() {
      try {
        this.$store.commit('SET_IS_LOADING_FOR_SUBMIT', true)
        await API.evote.insertVote({
          candidate_id: this.selectedCandidate.id,
          election_id: this.selectedCandidate.election_id,
        })
        this.$router.replace('/vote/success')
      } catch (error) {
        console.log(error)
        this.handleMsgErrors(error, 'Gagal memilih kandidat')
      } finally {
        this.$store.commit('SET_IS_LOADING_FOR_SUBMIT', false)
      }
    },
    async logout() {
      this.$store.commit('SET_IS_LOADING_FOR_SUBMIT', true)
      await this.$store.dispatch('user/logout')

      // Redirect to login page
      window.location.replace('/login/tps')
    },
  },
}
</script>
